<template>
    <section class="bill">
        <!--<div>
            <el-button type="primary" size="small" @click="openDialog(null,'chong')">￥ 充值</el-button>
        </div>-->
        <div class="table-title" style="margin-top:0;margin-bottom: 5px;">待处理账单</div>
        <r-e-table ref="communityTableRef1" :dataRequest="getUnPaidTableData" :columns="houseBillColumns" :height="300"
                   show-summary :summary-method="getSummaries">
            <el-table-column slot="toolbar" label="操作" width="150">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="look(row)">详情</span>
                        <span class="table-btn" v-if="!row.tuiFang" @click="update(row)">修改</span>
                        <span class="table-btn" v-if="!row.tuiFang && getButtonMenu('CANCLE_BILL_BUTTON')" @click="openDialog(row,'he')">核销</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <div class="table-title" style="margin-top:0;margin-bottom: 5px;">已处理账单</div>
        <r-e-table ref="communityTableRef2" :dataRequest="getPaidTableData" :columns="houseBillColumns" :height="300"
                   show-summary :summary-method="getSummaries">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="look(row)">详情</span>
                        <span class="table-btn" v-if="row.type==='500512'" @click="transfer(row)">转移</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <r-e-dialog title="详情" class="layer-contract-detail" :visible.sync="detailsDialogVisible" show-footer top="10vh" width="1700px"
                    @click-submit="detailsDialogVisible=false" @click-cancel="detailsDialogVisible=false" @close="detailsDialogVisible=false">
            <bill-details ref="billDetails" :billData="billData"/>
        </r-e-dialog>

        <layer-write-off-bill ref="layerWriteOffBill" @clickCancel="clickCancel"/>

        <dialog-add-bill ref="dialogAddBill" @clickCancel="clickCancel"/>

        <layer-transfer-deposit-bill ref="layerTransferDepositBill" @clickCancel="clickCancel" />
    </section>
</template>

<script>
    import { houseBillColumns } from "@/views/rental-management/tenant-management/data";
    import * as houseApi from "@/api/house-resource";
    import {buttonCookies} from "@storage/cookies";
    import {numberFormat} from "../../../../../utils/custom";
    export default {
        name: "bill",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                houseBillColumns: houseBillColumns(this),
                billData: null,
                detailsDialogVisible: false,
            };
        },
        components: {
            billDetails: () => import("@/views/bill-management/rent-withdrawal/components/billDetails"),
            layerWriteOffBill: () => import("@/views/bill-management/payment-record/components/layer-write-off-bill"),
            dialogAddBill: () => import("@/views/rental-management/house-resource/components/part/components/dialog-add-bill"),
            layerTransferDepositBill: () => import('@/views/bill-management/payment-record/components/layer-transfer-deposit-bill'),
        },
        async created() {
            // 获取用户uuid
            const {uuid} = this.tenantData;
            this.uuid = uuid;
        },
        methods: {
            async getUnPaidTableData(params) {
                // 获取用户未处理账单
                params.uuid = this.uuid;
                return houseApi.getHouseLeaserUnPaidApi(params);
            },

            getPaidTableData(params) {
                // 获取用户已处理账单
                params.uuid = this.uuid;
                return houseApi.getHouseLeaserPaidApi(params);
            },

            handleSearch() {
                this.$refs["communityTableRef1"].getTableData();
                this.$refs["communityTableRef2"].getTableData();
            },

            openDialog(data, type) {
                this.$refs["layerWriteOffBill"].openDialog(data);
            },

            async clickCancel() {
                await this.$emit('getDeposit');
                await this.$emit('handleSearch');
                this.handleSearch();
            },

            look(data) {
                this.billData = data;
                this.detailsDialogVisible = true;
            },

            getButtonMenu(menu) {
                return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
            },

            update(data) {
                this.$refs["dialogAddBill"].openDialog(data);
            },

            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) return prev + curr;
                            else return prev;
                        }, 0);
                        sums[index] = numberFormat(sums[index] / 100);
                    } else sums[index] = '';

                });
                return sums;
            },

            transfer(data){
                this.$refs["layerTransferDepositBill"].openDialog(data);
            }
        }
    }
</script>

<style scoped>

</style>